import { FC } from 'react'
import {
  AppleLogo,
  FacebookLogo,
  GithubLogo,
  Globe,
  InstagramLogo,
  LinkedinLogo,
  PinterestLogo,
  SpotifyLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'phosphor-react-sc'
import ImageElement from '../elements/image'
import { SocialLink as SocialLinkInterface, SocialLinkType } from '../elements/social-links.interface'
import IconLink from './icon-link'

interface SocialLinkProps {
  socialLink: SocialLinkInterface
  showTitle?: boolean
}

interface SocialIconProps {
  icon: SocialLinkType
}

const Icon: FC<SocialIconProps> = ({ icon }) => {
  switch (icon) {
    case 'facebook':
      return <FacebookLogo size={24} weight="fill" />
    case 'instagram':
      return <InstagramLogo size={24} weight="regular" />
    case 'linkedin':
      return <LinkedinLogo size={24} weight="fill" />
    case 'twitter':
      return <TwitterLogo size={24} weight="fill" />
    case 'apple':
      return <AppleLogo size={24} weight="fill" />
    case 'spotify':
      return <SpotifyLogo size={24} weight="fill" />
    case 'youtube':
      return <YoutubeLogo size={24} weight="fill" />
    case 'github':
      return <GithubLogo size={24} weight="fill" />
    case 'pinterest':
      return <PinterestLogo size={24} weight="fill" />
    case 'website':
      return <Globe size={24} weight="regular" />

    default:
      return <></>
  }
}

const labels: { [key in SocialLinkType]: string } = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  apple: 'Apple',
  spotify: 'Spotify',
  youtube: 'YouTube',
  github: 'Github',
  pinterest: 'Pinterest',
  website: 'Website',
  custom: 'Custom',
}

const SocialLink: FC<SocialLinkProps> = ({ socialLink, showTitle }) => {
  const { icon, customIcon, iconName, url } = socialLink

  return (
    <IconLink
      icon={
        icon !== 'custom' ? (
          <Icon icon={icon} />
        ) : (
          <ImageElement image={customIcon} className={'w-6 h-7 block'} responsive alt={iconName} />
        )
      }
      url={url}
      showTitle={showTitle}
      title={labels[icon]}
    />
  )
}

export default SocialLink
